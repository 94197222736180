import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SendIcon from '@mui/icons-material/Send';
import '../Contact/contact.css';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
  transition: 'transform 0.2s ease, box-shadow 0.3s ease',
  '&:hover': {
    backgroundColor: '#FFF7E5',
    transform: 'scale(1.02)',
    boxShadow: `0 0 16px 8px ${theme.palette.primary.main}`,
  },
}));

function Contact() {
  return (
    <div className="contact-container">
      <div className="contact-information-wrapper">
        <Item
          className="override-min-width"
          elevation={24}
          sx={{
            minWidth: 'unset',
            minHeight: '300px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#FFFFF0',
            borderRadius: '10px',
          }}
        >
          <div className="contact-information-text">
            <h1>Get in touch</h1>
            <p>What's next? Message me on LinkedIn or send through an email.</p>
          </div>
          <div className="contact-button-wrapper">
            <Button
              size="large"
              variant="contained"
              href="mailto:mcconachie.xander@gmail.com"
              elevation={24}
            >
              Say Hello
            </Button>
            <div classname="contact-icon-wrapper">
              <IconButton
                color="primary"
                size="large"
                aria-label="Navigate to LinkedIn"
                href="https://www.linkedin.com/in/alexander-mcconachie"
                target="_blank"
              >
                <LinkedInIcon sx={{ fontSize: 36 }} />
              </IconButton>

              <IconButton
                color="primary"
                aria-label="Send an email to Alex."
                href="mailto:mcconachie.xander@gmail.com"
              >
                <SendIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </div>
          </div>
        </Item>
      </div>
    </div>
  );
}

export default Contact;
