import Button from '@mui/material/Button';
import './main.css';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import VolcanoIcon from '../Images/VolcanoIcon.webp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SendIcon from '@mui/icons-material/Send';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import styled, { keyframes } from 'styled-components';

export default function Home() {
  const theme = useTheme();

  const FlashingDot = styled.span`
    animation: ${keyframes`
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.15;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  `} 2s infinite ease-in-out;
  `;

  return (
    <div className="home-wrapper" overflow="hidden">
      <div className="home-staticcontent-wrapper">
        <div className="home-card-wrapper">
          <p>Hello, my name is</p>
          <h1>
            Alex McConachie<FlashingDot>.</FlashingDot>
            <hr />
          </h1>
          <h3>Cloud Engineer with a passion for Full Stack Development</h3>
          <p>I love building things for the web and beyond.</p>
        </div>

        <div className="icon-button-wrapper">
          <IconButton
            color="primary"
            size="large"
            aria-label="Navigate to LinkedIn"
            href="https://www.linkedin.com/in/alexander-mcconachie"
            target="_blank"
          >
            <LinkedInIcon
              sx={{
                fontSize: 36,
                borderRadius: '6px',
                transition: 'transform 0.2s ease, box-shadow 0.3s ease',
                '&:hover': {
                  boxShadow: `0 0 16px 8px ${theme.palette.primary.main}`,
                  transform: 'scale(1.1)',
                },
              }}
            />
          </IconButton>

          <IconButton
            aria-label="A volcano themed icon."
            size="large"
            href="https://www.whoisalexm.com"
            onClick={e => {
              e.preventDefault();
              window.open('https://www.whoisalexm.com', '_blank');
            }}
            sx={{
              width: 36,
              height: 36,
              borderRadius: '6px',
              transition: 'transform 0.1s ease, box-shadow 0.3s ease',
              '&:hover': {
                boxShadow: '0 0 16px 8px rgba(255, 69, 0, 0.8)',
                transform: 'scale(1.1)',
              },
            }}
          >
            <img
              src={VolcanoIcon}
              className="icon"
              style={{
                width: '29px',
                height: '29px',
                objectFit: 'cover',
                margin: 'auto',
              }}
            />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="Send an email to Alex."
            href="mailto:mcconachie.xander@gmail.com"
          >
            <MailOutlineIcon
              sx={{
                fontSize: 36,
                borderRadius: '6px',
                transition: 'transform 0.2s ease, box-shadow 0.3s ease',
                '&:hover': {
                  boxShadow: `0 0 16px 8px ${theme.palette.primary.main}`,
                  transform: 'scale(1.1)',
                },
              }}
            />
          </IconButton>
        </div>
      </div>

      <div className="home-dynamiccontent-wrapper">
        <div className="home-about-wrapper">
          <h4>About</h4>
          <p>
            I have always loved building things and technology. My parents used
            science, aviation, communications equipment, and other electronic
            systems to provide me with additional learning opportunities outside
            of the classroom.
            <br />
            <br />I am currently dedicated to managing and securing cloud
            infrastructure while simultaneously completing a Bachelor's degree
            with a major in Computer Science at the Queensland University of
            Technology.
            <br />
            <br />
            In my spare time, I like to build projects with my Raspberry Pi,
            develop applications, learn new technologies, play Minecraft, Age of
            Empires, ride my bike, explore, and spend time with my family.
            <br />
            <br />
            <br />
          </p>
        </div>
        <div className="home-experience-wrapper">
          <h4>Awards</h4>
          <p>
            2024 - QUT Stimulate | Award for outstanding performance and
            proactive willingness to assist.
            <br />
            <br />
            2024 - QUT Stimulate | Peer Learning Leader Certificate of
            Participation. <br />
            <br />
            2023 - QUT Stimulate | Peer Learning Leader Certificate of
            Participation. <br />
            <br />
          </p>

          <h4>Volunteer Experience</h4>
          <p>
            <i>April 2023 - present:</i> QUT STIMulate
            <br />
            <br />
            My volunteer experience at QUT provides me with the opportunity to
            help students with their Science, Technology, Engineering, and
            Mathematics subjects. This experience continues to hone my
            programming ability across a variety of languages, problem-solving
            abilities, time management, leadership, and communication skills.
            <br />
            <br />
            <br />
          </p>

          <h4>Experience</h4>
          <p>
            <i>2023 - present:</i> Cloud Engineer | Idea 11.
            <br />
            <br />
            <i>2018 - 2024:</i> Home office IT, and PC customisation specialist
            (hobby business) | Ashgrove IT.
            <br />
            <br />
            <i>2013 - 2023:</i> Construction Economist / Estimator | McNab,
            Ashley Cooper, Niclin Group, Stoddart Group.
          </p>
        </div>
      </div>
    </div>
  );
}
