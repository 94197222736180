import '../Certification/certificates.css';
import Button from '@mui/material/Button';
import { useState } from 'react';
import AWSSAAssociate from '../Images/AWSSAAssociate.png';
import AWSDeveloperAssociate from '../Images/AWSDeveloperAssociate.png';
import AWSSysOpsAssociate from '../Images/AWSSysOpsAssociate.png';
import AWSCCP from '../Images/AWSCCP.png';
import MSAZ900 from '../Images/MSAZ900.png';
import MySQLAdvanced from '../Images/MySQLAdvanced.png';
import TAFEQLD from '../Images/TAFEQLD.png';
import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SendIcon from '@mui/icons-material/Send';

function Certificates() {
  return (
    <div className="Certificates">
      <h1>Certificates</h1>
      <div className="certificates-container">
        <div className="certificates-AWS-DeveloperAssociate">
          <div className="certificates-AWS-DeveloperAssociate-badge">
            <img src={AWSDeveloperAssociate} className="certificate-icon" />
          </div>
          <div className="certificates-AWS-DeveloperAssociate-desc">
            <p>
              Earning the AWS Developer Associate certification honed my skills
              in building, deploying, securing, optimising, and migrating
              scalable applications using various AWS tools including Lambda,
              SAM, CloudFormation, migrations from on-prem to cloud
              infrastructure, securing and exposing APIs with API Gateway,
              encryption, Secrets Manager, Parameter Store, CloudHSM, CloudWatch
              Logs, X-Ray, CI/CD pipelines, Kinesis Streams, relational RDS/SQL
              and non-relational DynamoDB databases.
            </p>
          </div>
        </div>

        <div className="certificates-AWS-SysOpsAssociate">
          <div className="certificates-AWS-SysOpsAssociate-badge">
            <img src={AWSSysOpsAssociate} className="certificate-icon" />
          </div>
          <div className="certificates-AWS-SysOpsAssociate-desc">
            <p>
              <br />I earned this certificate by deeply exploring AWS products
              including VPC's, infrastructure as code, automation, security, AWS
              internet gateway, SG's, NACL's, monitoring and logging, IAM, Route
              53, CloudFront CDN, RDS, Aurora, DynamoDB, WAFs, AWS CLI, AWS
              Developer Tools, CI/CD pipelines, ACM, ECR, and API Gateway.
            </p>
          </div>
        </div>

        <div className="certificates-AWS-SAAssociate">
          <div className="certificates-AWS-SAAssociate-badge">
            <img src={AWSSAAssociate} className="certificate-icon" />
          </div>
          <div className="certificates-AWS-SAAssociate-desc">
            <p>
              <br />
              This course provided me with a comprehensive understanding of AWS
              architecture and techniques with common and relatable scenarios I
              see day to day which helped earn the AWS Solutions Architect
              Associate certificate.
            </p>
          </div>
        </div>

        <div className="certificates-AWS-CCP">
          <div className="certificates-AWS-CCP-badge">
            <img src={AWSCCP} className="certificate-icon" />
          </div>
          <div className="certificates-AWS-CCP-desc">
            <p>
              <br />I earned the AWS Certified Cloud Practitioner Certificate
              prior to my first official IT role. On this journey, I learned
              broadly about AWS services and architecture.
            </p>
          </div>
        </div>

        <div className="certificates-MS-AZ900">
          <div className="certificates-MS-AZ900-badge">
            <img src={MSAZ900} className="certificate-icon" />
          </div>
          <div className="certificates-MS-AZ900-desc">
            <p>
              <br />
              The first certificate I earned before entering the IT industry. On
              this journey, I learned broadly about cloud services and
              architecture in the context of Microsoft Azure.
            </p>
          </div>
        </div>

        <div className="certificates-MySQL">
          <div className="certificates-MySQL-badge">
            <img className="verbose-image" src={MySQLAdvanced} />
          </div>
          <div className="certificates-MySQL-desc">
            <p>
              <br />
              This certificate validated what I had learned in database-specific
              subjects early on in my degree. Topics covered include advanced
              joining and union techniques used to filter for a desired outcome.
            </p>
          </div>
        </div>

        {/*  */}
        <div className="certificates-TAFEQLD">
          <div className="certificates-TAFEQLD-badge">
            <img className="verbose-image" src={TAFEQLD} />
          </div>
          <div className="certificates-TAFEQLD-desc">
            <p>
              <br />
              This Cyber Security Essentials certificate from TAFE QLD gave me a
              solid grounding in cybersecurity covering essential topics like
              network security, threat assessment, and incident response.
            </p>
          </div>
        </div>
        {/*  */}
      </div>
      <IconButton
        color="primary"
        aria-label="Navigate to LinkedIn"
        href="https://www.linkedin.com/in/alexander-mcconachie"
        target="_blank"
      >
        <LinkedInIcon />
      </IconButton>
      <IconButton
        color="primary"
        aria-label="Send an email to Alex."
        href="mailto:mcconachie.xander@gmail.com"
      >
        <SendIcon />
      </IconButton>
    </div>
  );
}

export default Certificates;
