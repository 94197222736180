import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './main.css';

export default function Navbar() {
  const navigate = useNavigate();
  const [tab, setTab] = useState('one');

  const changeTab = (e, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="nav-container">
      <Box>
        <Tabs
          value={tab}
          onChange={changeTab}
          textColor="primary"
          indicatorColor="primary"
          role="navigation"
          sx={{
            '& .MuiTab-root': {
              color: '  #ffa500',
              minWidth: '120px',
              margin: '0 2px',
              padding: '10px 20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            '& .Mui-selected': {
              color: 'primary',
            },
            '& .MuiTabs-indicator': {
              backgroundColor: 'primary.main',
            },
          }}
        >
          <Tab
            label="Home"
            onClick={() => {
              navigate('/');
            }}
          />
          <Tab
            color="white"
            label="Projects"
            onClick={() => {
              navigate('/Projects');
            }}
          />
          <Tab
            label="Certificates"
            onClick={() => {
              navigate('/Certificates');
            }}
          />
          <Tab
            label="Contact"
            onClick={() => {
              navigate('/Contact');
            }}
          />
        </Tabs>
      </Box>
    </div>
  );
}
