import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './main.css';

export default function MobileNavbar() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className="mobile-nav-container">
      <Button
        variant="contained"
        size="large"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ backgroundColor: '#ffa500', color: 'black', width: '80%' }}
      >
        <MenuIcon style={{ marginRight: '8px' }} />
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        size="lg"
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            navigate('/');
            closeMenu();
          }}
        >
          Home
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/Projects');
            closeMenu();
          }}
        >
          Projects
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/Certificates');
            closeMenu();
          }}
        >
          Certificates
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/Contact');
            closeMenu();
          }}
        >
          Contact
        </MenuItem>
      </Menu>
    </div>
  );
}
