import { MyContext } from './myCtx';
import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';

import './utils.css';

export default function NotificationModal() {
  const { clientNotification, setClientNotification } = useContext(MyContext);
  const { academicNotification, setAcademicNotification } =
    useContext(MyContext);

  const closeModal = () => {
    setClientNotification(null);
    setAcademicNotification(null);
  };

  return (
    <div>
      <Modal
        open={clientNotification}
        onClose={closeModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={clientNotification}>
          <Box className="modal-container">
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {academicNotification
                ? 'This project is being updated.'
                : 'This project is hidden to maintain academic integrity.'}
              <br />
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 4 }}>
              {academicNotification
                ? 'This project is currently being lifted, shifted, moved, or updated as part of routine maintenance. Please check in later or send me a message.'
                : 'Unfortunately, this application is still being adjusted to prevent students from copying my submissions as their own. I will be more than happy to share this with you privately. I appreciate your understanding.'}
              <br />
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 4 }}>
              <Button
                onClick={closeModal}
                size="large"
                variant="contained"
                elevation={24}
              >
                No problem
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
