import '../Projects/projects.css';

import { useContext, useState } from 'react';
import { MyContext } from '../Utils/myCtx';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Volcanoes5 from '../Images/Volcanoes5.png';
import Weather from '../Images/Weather.png';
import PythonRace from '../Images/PythonRace.png';
import PythonNewsScraper from '../Images/PythonNewsScraper.png';
import RPIStockImage2 from '../Images/RPIStockImage2.jpg';
import RPISecCam3 from '../Images/RPISecCam3.jpg';
import AWSRDS from '../Images/AWSRDS.png';
import AlexWeb from '../Images/AlexWeb.png';
import DungeonCrawl1 from '../Images/DungeonCrawl1.png';

const ThemedPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const ThemedCardActionArea = styled(CardActionArea)(({ theme }) => ({
  minHeight: '350px',
  display: 'flex',
  flexDirection: 'row',
  flexGrow: '1',
}));

const ThemedCardMedia = styled(CardMedia)(({ theme }) => ({
  maxWidth: '30%',
  maxHeight: '450px',
}));

const ThemedCard = styled(Card)(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: '#FFF7E5',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s ease, box-shadow 0.3s ease',
  '&:hover': {
    backgroundColor: '#FFF7E5',
    transform: 'scale(1.02)',
    boxShadow: `0 0 16px 8px ${theme.palette.primary.main}`,
  },
}));

function Projects() {
  const {
    clientNotification,
    setClientNotification,
    academicNotification,
    setAcademicNotification,
  } = useContext(MyContext);

  return (
    <div className="projects">
      <h1>Projects</h1>
      <div className="project-cards-container">
        {/*  */}
        <div className="project-card">
          <ThemedCard
            sx={{
              transition: 'transform 0.2s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: '0 0 16px 12px rgba(255, 69, 0, 0.8)',
              },
            }}
          >
            <ThemedCardActionArea
              onClick={() => {
                setClientNotification('notification');
              }}
            >
              <ThemedCardMedia
                component="img"
                image={Volcanoes5}
                alt="Image of a clean SPA for searching and finding volcanoes using REST API's"
                className="project-card-image"
              />
              <CardContent
                sx={{
                  width: '70%',
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  marginBottom="4%"
                >
                  Volcanoes of the World
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  marginBottom="4%"
                >
                  Designed, developed, and deployed this full stack web
                  application using MySQL, Express.js, React, and Node.js (MERN
                  stack). Hosted in AWS using CloudFront CDN for enhanced
                  performance, backed by JWTs, SSL / TLS certificates, and other
                  middleware for security. Leveraging various AWS services
                  including CloudFormation, Route 53, S3, EC2, and CloudFront,
                  this application demonstrates proficiency in full stack
                  software development including cloud engineering.
                  <br />
                  <br />
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  useFlexGap
                >
                  <ThemedPaper elevation={3}>React</ThemedPaper>
                  <ThemedPaper elevation={3}>Express.js</ThemedPaper>
                  <ThemedPaper elevation={3}>Ubuntu Linux</ThemedPaper>
                  <ThemedPaper elevation={3}>Node.js</ThemedPaper>
                  <ThemedPaper elevation={3}>REST API's</ThemedPaper>
                  <ThemedPaper elevation={3}>CORS</ThemedPaper>
                  <ThemedPaper elevation={3}>
                    SSL / TLS Certificates
                  </ThemedPaper>
                  <ThemedPaper elevation={3}>MySQL</ThemedPaper>
                  <ThemedPaper elevation={3}>Jest</ThemedPaper>
                  <ThemedPaper elevation={3}>Bcrypt</ThemedPaper>
                  <ThemedPaper elevation={3}>HTTPS</ThemedPaper>
                  <ThemedPaper elevation={3}>JSON Web Tokens</ThemedPaper>
                  <ThemedPaper elevation={3}>Insomnia</ThemedPaper>
                  <ThemedPaper elevation={3}>OpenAPI</ThemedPaper>
                  <ThemedPaper elevation={3}>Git</ThemedPaper>
                  <ThemedPaper elevation={3}>AWS</ThemedPaper>
                </Stack>
              </CardContent>
            </ThemedCardActionArea>
          </ThemedCard>
        </div>
        {/*  */} {/*  */}
        <div className="project-card">
          <ThemedCard>
            <ThemedCardActionArea
              onClick={() => {
                setClientNotification('notification');
                setAcademicNotification('notification');
              }}
            >
              <ThemedCardMedia
                component="img"
                image={RPIStockImage2}
                alt="Raspberry Pi SOC. Photo credit: Vishnu Monahan"
                className="project-card-image"
              />
              <CardContent
                sx={{
                  width: '70%',
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  marginBottom="4%"
                >
                  Raspberry Pi Object Recognition | Vehicle Counter
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  marginBottom="4%"
                >
                  Developed a vehicle counting system using a Rasperry Pi 4B and
                  a combination of background subtraction, Open CV, and
                  TensorFlow for object recognition. Results uploaded to an AWS
                  S3 bucket for future processing. Identified software and
                  hardware limitations and plan to integrate a Tensor Processing
                  Unit (TPU) to increase the performance and frame rate. This
                  performance increase will allow me to implement simultaneous
                  classification and counting while opening up further
                  opportunities in the real-time data analytics space.
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  useFlexGap
                >
                  <ThemedPaper elevation={3}>Python</ThemedPaper>
                  <ThemedPaper elevation={3}>
                    Debian Linux / Raspbian OS
                  </ThemedPaper>
                  <ThemedPaper elevation={3}>AWS S3</ThemedPaper>
                  <ThemedPaper elevation={3}>Open CV</ThemedPaper>
                  <ThemedPaper elevation={3}>ML</ThemedPaper>
                  <ThemedPaper elevation={3}>Raspberry Pi</ThemedPaper>
                  <ThemedPaper elevation={3}>Data Analytics</ThemedPaper>
                </Stack>
              </CardContent>
            </ThemedCardActionArea>
          </ThemedCard>
        </div>
        {/*  */}
        {/*  */}
        <div className="project-card">
          <ThemedCard>
            <ThemedCardActionArea
              onClick={() => {
                setClientNotification('notification');
                setAcademicNotification('notification');
              }}
            >
              <ThemedCardMedia
                component="img"
                image={AlexWeb}
                alt="image of a professional SPA by Alex McConachie"
                className="project-card-image"
              />
              <CardContent
                sx={{
                  width: '70%',
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  marginBottom="4%"
                >
                  This website!
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  marginBottom="4%"
                >
                  Developed this website to enhance single-page application,
                  cloud engineering, and cloud hosting skills across various
                  platforms while experimenting with different stacks. Utilised
                  AWS CloudFront global CDN, AWS S3 for static hosting, and
                  explored CI/CD pipelines to automate deployment.
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  useFlexGap
                >
                  <ThemedPaper elevation={3}>Javascript</ThemedPaper>
                  <ThemedPaper elevation={3}>Node.js</ThemedPaper>
                  <ThemedPaper elevation={3}>React</ThemedPaper>
                  <ThemedPaper elevation={3}>AWS CloudFront</ThemedPaper>
                  <ThemedPaper elevation={3}>HTTPS</ThemedPaper>
                  <ThemedPaper elevation={3}>
                    AWS Web-Application Firewall
                  </ThemedPaper>
                  <ThemedPaper elevation={3}>AWS S3</ThemedPaper>
                  <ThemedPaper elevation={3}>CI / CD</ThemedPaper>
                  <ThemedPaper elevation={3}>IaC</ThemedPaper>
                  <ThemedPaper elevation={3}>AWS Cloudformation</ThemedPaper>
                  <ThemedPaper elevation={3}>
                    AWS Certificate Manager
                  </ThemedPaper>
                  <ThemedPaper elevation={3}>AWS Route 53</ThemedPaper>
                </Stack>
              </CardContent>
            </ThemedCardActionArea>
          </ThemedCard>
        </div>
        {/*  */}
        {/*  */}
        <div className="project-card">
          <ThemedCard>
            <ThemedCardActionArea
              onClick={() => {
                setClientNotification('notification');
                setAcademicNotification('notification');
              }}
            >
              <ThemedCardMedia
                component="img"
                image={AWSRDS}
                alt="Amazon Relational Database Service icon."
                className="project-card-image"
              />
              <CardContent
                sx={{
                  width: '70%',
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  marginBottom="4%"
                >
                  AWS on-premise database migrations to RDS
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  marginBottom="4%"
                >
                  This project involved lifting and shifting an on-premises
                  database to Amazon RDS. Implented strategies for maintaining
                  high availability and improving resiliency using RDS read
                  replicas. Best practices implemented to improve security and
                  scalability.
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  useFlexGap
                >
                  <ThemedPaper elevation={3}>AWS RDS</ThemedPaper>
                  <ThemedPaper elevation={3}>MySQL</ThemedPaper>
                  <ThemedPaper elevation={3}>Linux</ThemedPaper>
                  <ThemedPaper elevation={3}>AWS IAM</ThemedPaper>
                  <ThemedPaper elevation={3}>RDS read replica</ThemedPaper>
                </Stack>
              </CardContent>
            </ThemedCardActionArea>
          </ThemedCard>
        </div>
        {/*  */}
        {/*  */}
        <div className="project-card">
          <ThemedCard>
            <ThemedCardActionArea
              onClick={() => {
                setClientNotification('notification');
                setAcademicNotification('notification');
              }}
            >
              <ThemedCardMedia
                component="img"
                image={RPISecCam3}
                alt="Raspberry Pi SOC"
                className="project-card-image"
              />
              <CardContent
                sx={{
                  width: '70%',
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  marginBottom="4%"
                >
                  Raspberry Pi Security Camera + Cloud
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  marginBottom="4%"
                >
                  Designed and deployed a Raspberry Pi-based security camera
                  system using Python, GPIO pins / breadboard setup, and a PIR
                  sensor. Integrated video streaming and motion detection
                  functionalities to capture and process data locally.
                  Configured AWS S3 for storing video footage and utilized AWS
                  SNS topics to send notifications via email to the owner upon
                  motion detection events.
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  useFlexGap
                >
                  <ThemedPaper elevation={3}>Python</ThemedPaper>
                  <ThemedPaper elevation={3}>Linux</ThemedPaper>
                  <ThemedPaper elevation={3}>Raspberry Pi IoT</ThemedPaper>
                  <ThemedPaper elevation={3}>AWS S3</ThemedPaper>
                  <ThemedPaper elevation={3}>AWS SNS</ThemedPaper>
                  <ThemedPaper elevation={3}>AWS IAM</ThemedPaper>
                </Stack>
              </CardContent>
            </ThemedCardActionArea>
          </ThemedCard>
        </div>
        {/*  */}
        {/*  */}
        <div className="project-card">
          <ThemedCard>
            <ThemedCardActionArea
              onClick={() => {
                setClientNotification('notification');
              }}
            >
              <ThemedCardMedia
                component="img"
                image={Weather}
                alt="sun coming out from behind a cloud."
                className="project-card-image"
              />
              <CardContent
                sx={{
                  width: '70%',
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  marginBottom="4%"
                >
                  Weather Forecast
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  marginBottom="4%"
                >
                  Developed a front-end weather forecast application to provide
                  accurate weather forecasts for various locations. Weather
                  information is queried from an external weather API and
                  returned to the user.
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  useFlexGap
                >
                  <ThemedPaper elevation={3}>React</ThemedPaper>
                  <ThemedPaper elevation={3}>Javascript</ThemedPaper>
                  <ThemedPaper elevation={3}>Insomnia</ThemedPaper>
                  <ThemedPaper elevation={3}>Node.js</ThemedPaper>
                  <ThemedPaper elevation={3}>REST API's</ThemedPaper>
                </Stack>
              </CardContent>
            </ThemedCardActionArea>
          </ThemedCard>
        </div>
        {/*  */}
        {/*  */}
        <div className="project-card">
          <ThemedCard>
            <ThemedCardActionArea
              onClick={() => {
                setClientNotification('notification');
              }}
            >
              <ThemedCardMedia
                component="img"
                image={DungeonCrawl1}
                alt="C# console application awaiting an in put from a user."
                className="project-card-image"
              />
              <CardContent
                sx={{
                  width: '70%',
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  marginBottom="4%"
                >
                  Dungeon Crawler
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  marginBottom="4%"
                >
                  An exploration game leveraging C# Object-Oriented Programming
                  principles and various algorithms including Dijkstra and A*
                  methods to traverse obstacles and avoid detection.
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  useFlexGap
                >
                  <ThemedPaper elevation={3}>C#</ThemedPaper>
                  <ThemedPaper elevation={3}>OOP</ThemedPaper>
                </Stack>
              </CardContent>
            </ThemedCardActionArea>
          </ThemedCard>
        </div>
        {/*  */}
        {/*  */}
        <div className="project-card">
          <ThemedCard>
            <ThemedCardActionArea
              onClick={() => {
                setClientNotification('notification');
              }}
            >
              <ThemedCardMedia
                component="img"
                image={PythonNewsScraper}
                alt="Python application using regular expressions to read and process retrieved XML. "
                className="project-card-image"
              />
              <CardContent
                sx={{
                  width: '70%',
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  marginBottom="4%"
                >
                  News Headline Scraper
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  marginBottom="4%"
                >
                  A Python program used to access and process xml from varying
                  news sources while displaying them in a basic gui. This was
                  one of my first assignments.
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  useFlexGap
                >
                  <ThemedPaper elevation={3}>Python</ThemedPaper>
                  <ThemedPaper elevation={3}>TKinter</ThemedPaper>
                  <ThemedPaper elevation={3}>Regular Expressions</ThemedPaper>
                  <ThemedPaper elevation={3}>XML</ThemedPaper>
                </Stack>
              </CardContent>
            </ThemedCardActionArea>
          </ThemedCard>
        </div>
        {/*  */}
        {/*  */}
        <div className="project-card">
          <ThemedCard>
            <ThemedCardActionArea
              onClick={() => {
                setClientNotification('notification');
              }}
            >
              <ThemedCardMedia
                component="img"
                image={PythonRace}
                alt="Turtle grpahics characters performing a race based on a randomly generated set of instructions."
                className="project-card-image"
              />
              <CardContent
                sx={{
                  width: '70%',
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  marginBottom="4%"
                >
                  Animal Race
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  marginBottom="4%"
                >
                  A Python program used to read and process a randomised set of
                  instructions for two competitors to complete a race within a
                  movement constraint. This was one of my first assignments.
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  useFlexGap
                >
                  <ThemedPaper elevation={3}>Python</ThemedPaper>
                  <ThemedPaper elevation={3}>Turtle Graphics</ThemedPaper>
                </Stack>
              </CardContent>
            </ThemedCardActionArea>
          </ThemedCard>
        </div>
        {/*  */}
      </div>
    </div>
  );
}

export default Projects;
