import './App.css';
import { useState } from 'react';
import { MyContext } from '../src/Components/Utils/myCtx';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import MobileNavbar from '../src/Components/Main/MobileHeader';
import Navbar from '../src/Components/Main/Header';
import Home from '../src/Components/Main/Home';
import Certificates from './Components/Certification/Certificates';
import Projects from '../src/Components/Projects/Projects';
import Contact from '../src/Components/Contact/Contact';
import NotificationModal from '../src/Components/Utils/NotificationModal';
import { ThemeProvider, createTheme } from '@mui/material/styles';

function App() {
  const [clientNotification, setClientNotification] = useState(null);
  const [academicNotification, setAcademicNotification] = useState(null);

  const theme = createTheme({
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div className="App">
          <MyContext.Provider
            value={{
              clientNotification,
              setClientNotification,
              academicNotification,
              setAcademicNotification,
            }}
          >
            <div className="header">
              <Navbar />
              <MobileNavbar />
            </div>
            {clientNotification ? <NotificationModal /> : <></>}
            <div className="App-body">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Certificates" element={<Certificates />} />
                <Route path="/Projects" element={<Projects />} />
                <Route path="/Contact" element={<Contact />} />
              </Routes>
            </div>
          </MyContext.Provider>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
